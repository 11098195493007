import React, { useState } from 'react';
import './Miniapp.css';

function Miniapp() {
  const [activeTab, setActiveTab] = useState('chat');
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [profile] = useState({
    name: 'User',
    email: 'user@example.com',
  });

  const handleSendMessage = () => {
    if (input.trim()) {
      setMessages([...messages, { sender: 'User', text: input }]);
      setInput('');
      // Simulate ChatGPT response
      setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages, { sender: 'ChatGPT', text: 'Hello! How can I assist you?' }]);
      }, 1000);
    }
  };

  return (
    <div className="miniapp">
      <header className="miniapp-header">Chat with ChatGPT</header>
      <main className="miniapp-content">
        {activeTab === 'chat' ? (
          <div className="chat-container">
            <div className="chat-messages">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`chat-message ${message.sender === 'User' ? 'user' : 'chatgpt'}`}
                >
                  <strong>{message.sender}:</strong> {message.text}
                </div>
              ))}
            </div>
            <div className="chat-input">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type a message..."
              />
              <button onClick={handleSendMessage}>Send</button>
            </div>
          </div>
        ) : (
          <div className="profile-container">
            <h2>Profile</h2>
            <p><strong>Name:</strong> {profile.name}</p>
            <p><strong>Email:</strong> {profile.email}</p>
          </div>
        )}
      </main>
      <footer className="miniapp-footer">
        <button
          className={activeTab === 'chat' ? 'active' : ''}
          onClick={() => setActiveTab('chat')}
        >
          Chat
        </button>
        <button
          className={activeTab === 'profile' ? 'active' : ''}
          onClick={() => setActiveTab('profile')}
        >
          Profile
        </button>
      </footer>
    </div>
  );
}

export default Miniapp;